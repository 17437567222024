import React, { useEffect } from 'react';
import Hero from '../Hero/Hero';
import styles from './Home.module.css';
import appStyles from '../../App.module.css';
import GenericButton from '../GenericButton/GenericButton';
import BrandReviews from '../BrandReviews/BrandReviews';
import { DoneBy } from '../DoneBy/DoneBy';
import StepsCarousel from '../StepsCarrousel';
import steps from '../../util/steps.json';
import { Shell } from '../Shell/Shell';
import { TablePricing } from '../TablePricing/TablePricing';

export const Home = (props) => {
  let apollo, waves, heroSmall, heroMedium, heroBig, zyman;

  if (window.webpSupported) {
    heroSmall = require('../../assets/images/webp/hero-big.webp');
    heroMedium = require('../../assets/images/webp/hero-big.webp');
    heroBig = require('../../assets/images/webp/hero-big.webp');
    apollo = 'ApolloWebp';
    waves = require('../../assets/images/webp/waves.webp');
    zyman = require('../../assets/images/webp/zyman.webp');
  } else {
    heroSmall = require('../../assets/images/png/hero-big.png');
    heroMedium = require('../../assets/images/png/hero-big.png');
    heroBig = require('../../assets/images/png/hero-big.png');
    apollo = 'Apollo';
    waves = require('../../assets/images/png/waves.png');
    zyman = require('../../assets/images/png/zyman.png');
  }

  let heroSrc = {
    small: heroSmall,
    medium: heroMedium,
    big: heroBig,
    default: heroBig,
  };

  useEffect(() => {
    const onScroll = (e) => {
      if (
        document.getElementById('content').scrollHeight - window.scrollY <
        document.getElementById('content').scrollHeight * 0.25
      ) {
        document.getElementById('fix').classList.add(styles.FixDinamic);
        document.getElementById('fix').classList.remove(styles.Fix);
      } else {
        document.getElementById('fix').classList.add(styles.Fix);
        document.getElementById('fix').classList.remove(styles.FixDinamic);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  });

  const handlePixel = () => {
    const pixel = document.createElement('img');
    pixel.setAttribute(
      'src',
      'https://www.facebook.com/tr?id=427318704548057&ev=ViewContent&noscript=1'
    );
    document.getElementById('header').appendChild(pixel);
  };

  const search = new URL(window.location.href).search;

  return (
    <Shell>
      <div className={styles.Container} id="content">
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt="facebookPixel"
            src="https://www.facebook.com/tr?id=427318704548057&ev=PageView&noscript=1"
          />
        </noscript>
        <div
          className={`${appStyles.ApolloBackground} ${appStyles[apollo]}`}
        ></div>

        <div className={styles.Fix} id="fix">
          <GenericButton
            to={`/quizkit${search}`}
            label="¡QUIERO MIS MOONS!"
            event={() => handlePixel()}
          />
        </div>

        <Hero srcset={heroSrc}>
          <div className={appStyles.CompleteDesc} id="pixelHome">
            <h1 className={appStyles.HeroTitle}>
              Alineación dental sin salir de casa
            </h1>
            <h1 className={appStyles.HeroDesc}>
              Endereza tus dientes más rápido que los métodos convencionales,{' '}
              <b>sin metal y hasta 65% más económico.</b>
            </h1>
            <div className={appStyles.ButtonContainer}>
              <div className={styles.FixButton} id="fix">
                <GenericButton
                  to={`/quizkit${search}`}
                  label="¡QUIERO MIS MOONS!"
                  event={() => handlePixel()}
                />
              </div>
            </div>
          </div>
        </Hero>

        {/* <div className={styles.ContainerHS}>
          <img src={hotSale} className={styles.ImageHS} alt="HotSale" />
        </div> */}

        <div className={styles.TablePricingContainer}>
          <div className={styles.TablePricingTitle}>
            Tu tratamiento completo desde:
          </div>
          <TablePricing />
        </div>

        <div className={styles.WavesContainer}>
          <img className={styles.Image} src={waves} alt="Moons" />
        </div>
        <div className={appStyles.BrandContainer}>
          <BrandReviews />
        </div>

        <div className={styles.CarrouselContainer}>
          <StepsCarousel info={steps.data} hasDots={true} />
        </div>

        <div className={appStyles.CarouselContainer}>
          <DoneBy img={zyman} search={search} event={handlePixel} />
        </div>
      </div>
    </Shell>
  );
};
