const BRANDS = {
  mx: [
    {
      brand: "Forbes",
      img: window.webpSupported
        ? require("../../assets/images/webp/forbes.webp")
        : require("../../assets/images/png/forbes.png"),
      quote:
        "“Ortodoncia accesible para todos sin recurrir a los molestos brackets”",
    },
    {
      brand: "Excelsior",
      img: window.webpSupported
        ? require("../../assets/images/webp/excelsior.webp")
        : require("../../assets/images/png/excelsior.png"),
      quote:
        "“moons revoluciona la ortodoncia para tener una sonrisa perfecta sin brackets”",
    },
    {
      brand: "Elle",
      img: window.webpSupported
        ? require("../../assets/images/webp/elle.webp")
        : require("../../assets/images/png/elle.png"),
      quote:
        "“La alternativa a los brackets discreta, rápida y sin sacrificios”",
    },
    {
      brand: "Expansión",
      img: window.webpSupported
        ? require("../../assets/images/webp/expansion.webp")
        : require("../../assets/images/png/expansion.png"),
      quote:
        "“moons tiene la misión de reducir el costo y las visitas al ortodoncista”",
    },
    {
      brand: "TechCrunch",
      img: window.webpSupported
        ? require("../../assets/images/webp/techcrunch.webp")
        : require("../../assets/images/png/techcrunch.png"),
      quote: "“moons está listo para conquistar el mercado de Latinoamérica”",
    },
  ],
};

export default BRANDS;
