import React from "react";
import styles from "./DoneBy.module.css";
import GenericButton from "../GenericButton/GenericButton";

export const DoneBy = ({ img, search, event }) => {
  const Title = () => (
    <div className={styles.Title}>Hecho por Ortodoncistas</div>
  );

  const Image = () => (
    <div className={styles.ImageContainer}>
      <img src={img} className={styles.Image} alt="Moonlight kit" />
    </div>
  );

  const Content = () => (
    <div className={styles.InfoContainer}>
      <div className={styles.Text}>
        Tus moons son diseñados por nuestro equipo de Ortodoncistas dirigido por
        el Dr. Yair Zyman, con{" "}
        <b>
          más de 20 años de experiencia y certificado por la Asociación Mexicana
          de Ortodoncia.
        </b>
      </div>
      <div className={styles.ButtonContainer}>
        <GenericButton
          to={`/quizkit${search}`}
          label="¡QUIERO MIS MOONS!"
          event={event}
        />
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.MobileContainer}>
        <Title />
        <Image />
        <div className={styles.ContentMobile}>
          <Content />
        </div>
      </div>
      <div className={styles.DeskContainer}>
        <Image />
        <div className={styles.TIContainer}>
          <Title />
          <Content />
        </div>
      </div>
    </>
  );
};
