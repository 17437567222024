import React from "react";
import style from "./index.module.css";
import Desktop from "./desktop/desktop";
import Mobile from "./mobile/mobile";
import Tablet from "./tablet/tablet";

const StepsCarousel = ({ info, hasDots }) => {

  return (
    <div className={style.CarouselContainer}>
      <div className={style.ContainerTitle}>
        <div className={style.SectionTitle}>Una sonrisa increíble en 3 pasos</div>
      </div>
      <Mobile info={info} hasDots={hasDots} />
      <Tablet info={info} hasDots={hasDots} />
      <Desktop info={info} hasDots={hasDots} />
    </div>
  );
};

export default StepsCarousel;
