import React, { useState, useEffect } from "react";
import styles from "./BrandReviews.module.css";
import BRANDS from "./brands";

const BrandReviews = ({ domain = "mx" }) => {
  const [active, toggleActive] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      toggleActive((a) => {
        if (a === BRANDS[domain].length - 1) {
          return 0;
        }
        return a + 1;
      });
    }, 6000);
    return () => clearInterval(interval);
  }, [domain]);

  return (
    <div className={styles.Container}>
      <div className={styles.ReviewScore}>
        <img
          alt="Calificaciónes de usuarios de moons"
          className={styles.ScoreImage}
          src={require("../../assets/images/svg/moons-rating.svg")}
        />
        <div className={styles.QuotesContainer}>
          {BRANDS[domain].map((item) => (
            <p
              key={item.brand}
              className={styles.ReviewQuote}
              style={{ transform: `translateX(-${active}00%)` }}
            >
              {item.quote}
            </p>
          ))}
        </div>
      </div>
      <div className={styles.Separator}>
        <div
          className={styles.ActiveIndicator}
          style={{
            transform: `translateX(${active}00%)`,
            width: `${100 / BRANDS[domain].length}%`,
          }}
        />
      </div>
      <div className={styles.LogosContainer}>
        {BRANDS[domain].map((item, idx) => (
          <div
            key={item.brand}
            onClick={() => toggleActive(idx)}
            className={`${styles.LogoElement} ${
              active === idx ? styles.LogoActive : null
            }`}
            style={{ width: `${100 / BRANDS[domain].length}%` }}
          >
            <img
              alt={`Logo de ${item.brand}`}
              src={item.img}
              className={styles.LogoImage}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandReviews;
