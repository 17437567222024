import React from "react";
import styles from "./TablePricing.module.css";

export const TablePricing = (props) => {
  const Price = ({ high, low }) => (
    <div className={styles.CellBody}>
      <div className={styles.PriceHigh}>{high}</div>
      {/* <div className={styles.PriceLow}>{low}</div> */}
      <div className={styles.Iva}>IVA Incluido</div>
    </div>
  );

  const Title = ({ type, jump }) => (
    <div className={styles.CellHeader}>
      moons {jump && <br />} {type}
    </div>
  );

  /*   const imgHotSale = window.webpSupported
    ? require("../../assets/images/webp/logohotsale.webp")
    : require("../../assets/images/png/logohotsale.png"); */

  return (
    <>
      <div className={styles.Movil}>
        {/* <img src={imgHotSale} className={styles.Image} alt="HotSale" /> */}
        <div className={styles.PriceContainer}>
          <Price high={"$18,990"} low={"$16,490"} />
          <Title type={"(casos sencillos)"} jump />
          <div className={styles.Rectangle} />
        </div>
      </div>
      <div className={styles.Desk}>
        <div className={styles.HeaderContainer}>
          <Title type={"(casos sencillos)"} />
          <Title type={"(casos moderados)"} />
          <Title type={"(casos complejos)"} />
        </div>
        <div className={styles.BodyContainer}>
          <Price high={"$18,990"} low={"$16,490"} />
          <Price high={"$22,990"} low={"$19,490"} />
          <Price high={"$29,990"} low={"$27,490"} />
        </div>
        {/* <img src={imgHotSale} className={styles.Image} alt="HotSale" /> */}
      </div>
    </>
  );
};
