import React from "react";
import styles from "./Header.module.css";
import imgLogo from "../../assets/images/svg/moons.svg";
import { Link } from "react-router-dom";

export const Header = (props) => {
  return (
    <div className={styles.Container} id="header">
      <Link to="/">
        <img className={styles.ImgLogo} src={imgLogo} alt="Moonlight" />
      </Link>
    </div>
  );
};
