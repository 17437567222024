import React, { createRef } from 'react';
import style from './mobile.module.css';
import Description from '../description/description';
import { loadedImg, dinamicRef } from '../utils';

class Mobile extends React.Component {

    constructor(props) {
        super(props);

        this.curLeft = 0
        this.moveX = 0
        this.startX = 0
        this.curSlide = 0
        this.loadedCnt = 0;
        this.slideW = 0;
        this.totalSlides = this.props.info.length;

        this.refSlider = createRef();
        this.refSliderContainer = createRef();
        this.refAllSlide = dinamicRef(this.totalSlides);
        this.refDots = createRef();

        this.def = {
            transition: {
                speed: 300,
                easing: '',
                grow: 10
            },
            swipe: true,
            autoHeight: false,
            afterChangeSlide: () => { }
        }
        this.state = {
            footerPosition: 0,
            isChanging: false,
        }
    }

    componentWillMount() {
        window.addEventListener('resize', this.updateSliderDimension);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSliderDimension);
    }

    componentDidMount() {
        this.init();
        this.updateSliderDimension();
    }

    componentDidUpdate(nextProps, nextState) {
        if (this.state.isChanging !== nextState.isChanging && this.state.isChanging) {
            this.changeDimentionImage()
        }
    }

    gotoSlide = (n) => {
        if (n !== undefined) {
            this.curSlide = n;
        }
        this.refSliderContainer.current.style.transition = `left ${this.def.transition.speed / 1000}s ${this.def.transition.easing}`;
        if (this.curSlide === 0) {
            this.refSliderContainer.current.style.left = `0px`
        } else {
            this.refSliderContainer.current.style.left = `${-this.curSlide * (this.slideW - this.def.transition.grow/2)}px`
        }

        setTimeout(() => {
            this.refSliderContainer.current.style.transition = ''
        }, this.def.transition.speed);

        this.setDot();
        this.setState({
            isChanging: true
        });
    }

    changeDimentionImage = (clear) => {
        this.refAllSlide.forEach((child, index) => {
            if (clear) {
               child.current.style.transform = ''
            } else if (this.curSlide === index) {
               child.current.style.transform = `scale(1)`
                if (this.state.isChanging) this.setState({isChanging: false})
            } else {
                child.current.style.transform = `scale(0.9)`
                if (this.state.isChanging) this.setState({isChanging: false})
            }
        })
    }

    setDot = () => {
        let children = this.refDots.current.children;
        for (let i = 0; i < children.length; i++) {
            if (i === this.curSlide) {
                children[i].classList.add(style.SliderActive);
                children[i].classList.remove(style.SliderBotton);
            } else {
                children[i].classList.add(style.SliderBotton);
                children[i].classList.remove(style.SliderActive);
            }
        }
        this.setState({
            footerPosition: this.curSlide
        });
    }

    startMove = (e) => {
        this.getCurrentLeft();
        const touch = e.targetTouches[0] || e.changedTouches[0];
        this.startX = touch.pageX;
        this.increment = 0;
    }

    Moving = (e) => {
        const touch = e.targetTouches[0] || e.changedTouches[0];
        this.moveX = touch.pageX;
        const dir = this.startX < this.moveX ? -1 : 1;
        this.increment +=0.1;
       // if (Math.abs(this.moveX - this.startX) < 40) return;

        this.refSliderContainer.current.style.left = `${this.curLeft + this.moveX - this.startX}px`

        if (this.curSlide + dir >= 0 && this.curSlide + dir < this.totalSlides) {
           this.changeDimentionImage(false, this.increment, dir);
       }
    }

    endMove = (e) => {
        this.getCurrentLeft();

        if (Math.abs(this.moveX - this.startX) === 0) return;

        const stayAtCur = Math.abs(this.moveX - this.startX) < 40 || this.moveX === 0 ? true : false;
        const dir = this.startX < this.moveX ? 'left' : 'right';

        if (!stayAtCur) {
            dir === 'left' ? this.curSlide-- : this.curSlide++;
            if (this.curSlide < 0) {
                this.curSlide = 0;
            } else if (this.curSlide === this.totalSlides) {
                this.curSlide--;
            }
        }

        this.gotoSlide();
        this.restValues();
    }

    restValues = () => {
        this.startX = 0;
        this.moveX = 0;
    }

    getCurrentLeft = () => {
        const left = this.refSliderContainer.current.style.left
        if (left) this.curLeft = parseInt(left, 10);
    }

    updateSliderDimension = (e) => {
        if (this.refSlider && this.refSlider.current && this.refSlider.current.style) {
            this.refSlider.current.style.left = `${- this.slideW * this.curSlide}px`;
        }
        let out = 18.5 * window.innerWidth / 100;
        this.refSliderContainer.current.style.width = `${(window.innerWidth - out) * this.totalSlides}px`;

        this.changeDimentionImage(true); //clear width to 100%
        this.getSlideW();
        this.changeDimentionImage();

        if(this.slideW)
        this.refSliderContainer.current.style.minHeight = `${this.slideW + 10}px`
    }

    getSlideW = () => {
        const allSlider = this.refAllSlide;
        if (allSlider && allSlider.length > 0 && allSlider[0] && allSlider[0].current) {
            let node = allSlider[this.curSlide].current;
            this.slideW = parseInt(node.offsetWidth);
            //this.slideMargin = getStyleItemByProperty(node, 'margin-right');
        } else {
            this.slideW = 0
        }
    }

    init = () => {
        let allSlide = this.refAllSlide;

        for (let item of allSlide) {
            loadedImg(item.current, this.updateSliderDimension, this.totalSlides, false);
        }
        this.refSliderContainer.current.style.left = `0px`
        this.setDot();
        this.getSlideW();
    }

    render() {
        const { info } = this.props;
        return (
            <div className={style.CarouselMovil}>
                <div ref={this.refSliderContainer} onTouchStart={(e) => this.startMove(e)} onTouchMove={(e) => this.Moving(e)} onTouchEnd={(e) => this.endMove(e)} className={style.SlideshowContainer}>
                    <div ref={this.refSlider} className={style.SliderMove} >
                        {info.map((item, index) => {
                            let image = window.webpSupported ? item.webpMedium : item.jpgMedium;
                            return (
                                <div key={index} ref={this.refAllSlide[index]} className={style.StepContainerImages}>
                                    <img alt="step-one" data-src={image} className={style.ImageSlider} />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div ref={this.refDots} className={style.SliderBootOut}>
                    {info.map((item, index) => {
                        return (<button key={index} onClick={(e) => { this.gotoSlide(index) }}>
                        </button>)
                    })}
                </div>

                {info.map((item, index) => {
                    if (this.state.footerPosition === index)
                        return (<Description key={item.id} item={item}></Description>)
                    else return '';
                })}
            </div>
        );
    }
}
export default Mobile;