import React from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import styles from "./Shell.module.css";

export const Shell = (props) => {
  return (
    <>
      <Header />
      <div className={styles.Container}>{props.children}</div>
      <Footer />
    </>
  );
};
